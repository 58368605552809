<template>
  <div class="app-container">
    <div style="display: flex;justify-content: space-between;">
      <div>
        <el-input placeholder="请输入客户名称或合同（订单号）" v-model="listQuery.nameOrNumber" style="width: 190px;margin-left:5px" size="small" @keyup.enter="getList" clearable/>
        <el-button
          type="primary"
          @click="getList"
          size="small"
          style="margin-right: 5px"
          icon="Search"
          >搜索</el-button
        >
        <search @success="getList" @cancel="cancel" name="高级筛选">
          <el-form
            style="width: 400px;"
            label-width="110px"
          >
            <el-form-item label="创建时间："> 
              <el-date-picker
                style="width:110px;"
                size="small"
                v-model="listQuery.createdTimeSt"
                type="date"
                placeholder="起始日期"
                clearable
                value-format="YYYY-MM-DD"
              /> &nbsp;-&nbsp;
              <el-date-picker
                style="width:110px;"
                size="small"
                v-model="listQuery.createdTimeEd"
                type="date"
                placeholder="终止日期"
                clearable
                value-format="YYYY-MM-DD"
              />
            </el-form-item>
            <el-form-item label="业务类型："> 
              <cascader-common
                v-model:modelValue="listQuery.businessTypeId"
                placeholder="请选择业务类型"
                :options="contractTypeOptions"
                :casProps="casProps"
                style="width: 234px"
              />
            </el-form-item>
            <el-form-item label="任务负责人："> 
              <selectuser code="addCustomer" v-model:userId="listQuery.userId" :clearable="false" :multiples="false" style="width: 234px;margin: 0;" ></selectuser>
            </el-form-item>
            <el-form-item label="步骤办理人："> 
              <selectuser code="addCustomer" v-model:userId="listQuery.handleId" :clearable="false" :multiples="false" style="width: 234px;margin: 0;" ></selectuser>
            </el-form-item>
            <el-form-item label="步骤名称："> 
              <el-input placeholder="" v-model="listQuery.stepName" style="width: 234px;margin:0" size="small" clearable/>
            </el-form-item>
            <el-form-item label="办理状态："> 
              <el-select
                v-model="listQuery.status"
                placeholder="请选择"
                style="width: 234px; margin: 0"
                filterable
                clearable
                size="small"
              >
                <el-option
                  v-for="item in handleStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div>
        <el-button
          type="primary"
          @click="addTask"
          size="small"
          icon="Plus"
          >新增</el-button
        >
        <el-button
          type="primary"
          @click="endTask(1)"
          size="small"
          >完成</el-button
        >
        <el-button
          type="primary"
          @click="endTask(2)"
          size="small"
          >终止</el-button
        >
        <el-button
          type="primary"
          @click="delPatch"
          size="small"
          >删除</el-button
        >
        <el-button
          type="primary"
          @click="daochu"
          size="small"
          >导出</el-button
        >
      </div>
    </div>
    <!-- 列表 -->
    <div>
      <el-table
        :data="taskList"
        style="width: 100%; margin-top: 10px"
        border
        :height="contentStyleObj"
        @selection-change="handleSelectClue"
        v-loading="listLoading"
        stripe
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column type="selection" width="55" align="center" fixed />
        <el-table-column
          prop="customerNum"
          label="客户编码"
          min-width="100"
          align="left"
        />
        <el-table-column
          prop="customerName"
          label="客户名称"
          min-width="120"
          align="left"
        >
          <template #default="scope">
            <span class="clickable_style" @click="() => currentDetails(scope.row)">{{
              scope.row.customerName
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="businessType"
          label="业务类型"
          min-width="100"
          align="left"
        />
        <el-table-column
          prop="userName"
          label="负责人"
          min-width="80"
          align="left"
        />
        <el-table-column
          prop="handleName"
          label="处理人"
          min-width="80"
          align="left"
        />
        <el-table-column
          prop="status"
          label="状态"
          min-width="100"
          align="left"
        >
          <template #default="scope">
            <span>{{ statusEnum[scope.row.status] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="doneTime"
          label="完成时间"
          min-width="80"
          align="left"
        />
        <el-table-column
          prop="inputClerkName"
          label="创建人"
          min-width="100"
          align="left"
        />
        <el-table-column
          prop="createdTime"
          label="创建时间"
          min-width="100"
          align="left"
        />
        <el-table-column
          label="操作"
          align="center"
          width="150"
          fixed="right"
        >
          <template #default="scope">
            <el-button
              type="primary"
              size="small"
              link
              @click="() => copyThis(scope.row)"
              style="margin: 0;padding: 5px 2px"
            >复制</el-button>
            <el-button
              type="primary"
              size="small"
              link
              @click="() => delThis(scope.row)"
              style="margin: 0;padding: 5px 2px"
              :disabled="scope.row.status !== '1'"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="page_container">
      <qzf-pagination
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList()"
      />
    </div>

    <!-- 新增 -->
    <taskform ref="taskform" :title="titleDrawer" @success="getList"></taskform>

    <el-dialog v-model="terminationVisible" width="300px" title="终止任务">
      <div>
        <p><el-icon><InfoFilled /></el-icon>&nbsp;&nbsp;&nbsp;确定要终止任务么</p>
        <el-input v-model="terminationReason" placeholder="请输入终止原因" type="textarea" :rows="4" />
      </div>
      <template #footer>
        <el-button @click="terminationCancel">取 消</el-button>
        <el-button type="primary" @click="terminationConfirm">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { InfoFilled } from '@element-plus/icons-vue'
import selectBusiness from "../../crm/components/selectBusiness.vue";
import { contractTypeList,taskList, delTask, changeStatusTask, downloadTask } from "@/api/newCrm.js";
import { taskStatus } from "@/utils/commonData";
import taskform from "./components/taskForm.vue";
import { getKeyValue, downloadByUrl, DETAILS } from './utils'

export default {
  name: '',
  components: {
    selectBusiness,
    taskform,
    InfoFilled,
  },
  props: {},
  data () {
    return {
      listQuery: {
        page: 1,
        limit: 20,
        nameOrNumber: '',
        createdTimeSt: '',
        createdTimeEd: '',
        businessTypeId: null,
        userId: null,
        handleId: null,
      },
      taskList: [],
      total: 0,
      options: [],
      categoryList: [],
      form: {
        server: []
      },
      addDrawer: false,
      taskForm: {},
      casProps: {
        expandTrigger: "hover",
        label: "name",
        value: "id",
      },
      contractTypeOptions: [],//业务类型
      ids: [],
      select: [],
      handleStatusList: taskStatus,//办理状态
      titleDrawer:'',//抽屉title
      statusEnum: getKeyValue(taskStatus),
      terminationVisible: false,
      terminationReason: '',
    }
  },
  computed: {},
  watch: {},
  created () {
    this.getBussList()
    this.contentStyleObj = this.$getHeight(210);
    this.getList()
  },
  methods: {
    cancel() {
      this.listQuery = {
        page: 1,
        limit: 10,
        nameOrNumber: '',
        createdTimeSt: '',
        createdTimeEd: '',
        businessTypeId: null,
        userId: null,
        handleId: null,
      }
      this.getList()
    },
    daochu() {
      downloadTask({ ids: this.ids }).then((res) => {
        if (res.data.msg == "success") {
          window.open(res.data.data?.url, '_blank')
        }
      });
    },
    currentDetails(row) {
      this.titleDrawer = '详情'
      this.$refs.taskform.init(row, DETAILS)
    },
    copyThis(row) {
      this.titleDrawer = '复制任务'
      this.$refs.taskform.init(row)
    },
    delThis(row) {
      this.$confirm('确定删除？').then(() => {
        delTask({ id: [row.id] }).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("删除成功")
            this.getList()
          }
        });
      })
    },
    delPatch() {
      if (!this.ids.length) {
        return this.$message.error('请选择要删除的任务')
      }
      const not1 = this.select.filter(v => v.status != 1)
      if (not1.length) {
        return this.$message.error('只有未开始的任务才可以删除')
      }
      this.$confirm('确定删除？').then(() => {
        delTask({ id: this.ids }).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("删除成功")
            this.getList()
          }
        });
      })
    },
    getBussList(){
      contractTypeList({ contractType: '2' }).then((res) => {
        this.contractTypeOptions = res.data.data.list;
      });
    },
    getList(){
      if(this.listQuery.status == ''){
        this.listQuery.status = null
      }
      taskList(this.listQuery).then((res) => {
        if (res.data.msg == "success") {
          this.taskList = res.data.data.data
          this.listLoading = false
        }
      });
    },
    addTask(){
      this.titleDrawer = '新增任务'
      this.$refs.taskform.init()
    },
    terminationCancel() {
      this.terminationVisible = false
      this.terminationReason = ''
    },
    terminationConfirm () {
      changeStatusTask({
        type: 2,
        ids: this.ids,
        reason: this.terminationReason,
      }).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("操作成功")
          this.terminationVisible = false
          this.getList()
        }
      })
    },
    endTask(type){
      if(this.select.find(item => item.mm == 1)){
        this.$qzfMessage("所选任务中包含已完成或终止任务!请重新选择")
        return
      }
      if (type === 2) {
        this.terminationVisible = true
      } else { 
        changeStatusTask({
          type,
          ids: this.ids,
        }).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("操作成功")
            this.getList()
          }
        })
      }
    },
    // 列表勾选
    handleSelectClue(val) {
      this.ids = []
      this.select = []
      this.select = val;
      this.select.map((v,i)=>{
        this.ids.push(v.id)
      })
    },
     // 业务类型相关操作
     getOptions() {
      // 初始化 options
      this.options = [];

      // 确保 categoryList 和 form.server 都存在
      if (!this.categoryList || !this.form.server) return;

      // 创建一个映射表用于快速查找
      const serverMap = new Map();
      this.form.server.forEach((item) => {
        if (!item.id) {
          // 设置 disposable 属性
          item.disposable = item.categoryNameId === 5 ? "0" : "1";
        }
        // 使用 Map 存储 id 对应的 item
        serverMap.set(item.categoryNameId, item);
      });

      // 遍历 categoryList 并添加符合条件的子项
      this.categoryList.forEach((v) => {
        v.children.forEach((k) => {
          if (serverMap.has(k.id)) {
            this.options.push(k);
          }
        });
      });

      // 去重
      this.options = [...new Set(this.options.map((option) => option.id))].map(
        (id) => {
          return this.options.find((option) => option.id === id);
        }
      );
    },

    getCategory() {
      categoryListNew({}).then((res) => {
        if (res.data.msg == "success") {
          this.categoryList = res.data.data.list ? res.data.data.list : [];
          this.getOptions();
        }
      });
    },
  }
}
</script>

<style scoped lang="less">
.page_container{
  float: right;
  margin-top: 10px;
}
</style>
